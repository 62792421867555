import { Component, Input, OnInit } from '@angular/core';
import { NavController, PopoverController } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.page.html',
  styleUrls: ['./popover.page.scss'],
})
export class PopoverPage implements OnInit {
  @Input() text: string;
  @Input() id: string;
  @Input() type: number;
  titulo :string;
  dataUser: any = [];
  constructor(private popover:PopoverController,
    public navCtrl: NavController,
    private auth: AuthService,
    private service: DataService,) {

      
     }

  ngOnInit() {
    
    this.dataUser = this.auth.getUser();
    if(this.type === 0){
      this.titulo = 'Actualización de Datos'
    }else
    if(this.type === 268){
      this.titulo = 'Noticia Importante!'
    }else
    if(this.type === 269){
      this.titulo = 'Mensaje Importante!'
    }else{
      this.titulo = 'Comunicado Importante!'
    }
  }
  ClosePopover(not = '')
   {

    if(not != ''){
      this.navCtrl.navigateRoot('notificacion/'+not);
    }
     this.popover.dismiss();
   }

   OkPopover(id)
   {

    console.log(id);
    this.service.getNotificacionleida(id, this.dataUser.empleado.identificacion).subscribe((data) => {
      console.log('leida');
      console.log(data);
    });
     this.popover.dismiss();
   }
   actuadatos(){
    this.navCtrl.navigateRoot('/actdat');
    this.popover.dismiss();
   }

}
