import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment, url_path } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  url = url_path+"elite/services";
  options = {
    headers: {
      "Accept": 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    }
  };
  constructor(private http: HttpClient) { }
  getTyc() {
    return this.http.get(this.url + "/terminos");
  }

  getVersion() {
    return this.http.get(this.url + "/version");
  }  getPopover(user) {
    return this.http.post(this.url + "/popup", 'user=' + user, this.options);
  }

  getNoticias() {
    return this.http.post(this.url + "/noticias", '', this.options);
  }


  getHome(id, rol, codigo) {
    return this.http.post(this.url + '/home', 'user=' + id + "&rol=" + rol + "&codigo=" + codigo, this.options);
  }
  getDataemic(user) {
    return this.http.post(this.url + "/valoremic", 'user=' + user, this.options);
  }


  getVideos(user) {
    return this.http.post(this.url + "/videos", "user=" + user, this.options);
  }
  getEnvivos(user) {
    return this.http.post(this.url + "/envivos", "user=" + user, this.options);
  }

  getCategorias() {
    return this.http.get(this.url + "/getcategorias");
  }
  getCategoriasp() {
    return this.http.get(this.url + "/getcategoriasp");
  }
  getConvenios(cat) {
    return this.http.post(this.url + "/convenios", 'categoria=' + cat, this.options);
  }
  getConveniosDes() {
    return this.http.get(this.url + "/conveniosdest");
  }
  getConvenio(id) {
    return this.http.post(this.url + "/convenio", 'id=' + id, this.options);
  }
  getConvenioprodserv(id) {
    return this.http.post(this.url + "/convprodserv", 'id=' + id, this.options);
  }
  getRadio() {
    return this.http.get(this.url + "/radio");
  }
  setViewVideos(id, user) {
    return this.http.post(this.url + "/viewvideos", 'id=' + id + "&user=" + user, this.options);
  }
  setLikeVideos(id, user) {
    return this.http.post(this.url + "/likevideos", 'id=' + id + "&user=" + user, this.options);
  }
  getVideoviewlikes(id, user) {
    return this.http.post(this.url + "/videoviewlikes", 'id=' + id + "&user=" + user, this.options);
  }

  setViewEnvivos(id, user) {
    return this.http.post(this.url + "/viewenvivos", 'id=' + id + "&user=" + user, this.options);
  }
  setLikeEnvivos(id, user) {
    return this.http.post(this.url + "/likeenvivos", 'id=' + id + "&user=" + user, this.options);
  }
  getEnvivoviewlikes(id, user) {
    return this.http.post(this.url + "/envivoviewlikes", 'id=' + id + "&user=" + user, this.options);
  }

  setViewConvenios(id, user) {
    return this.http.post(this.url + "/viewconvenios", 'id=' + id + "&user=" + user, this.options);
  }
  setLikeConvenios(id, user) {
    return this.http.post(this.url + "/likeconvenios", 'id=' + id + "&user=" + user, this.options);
  }
  getConvenioviewlikes(id, user) {
    return this.http.post(this.url + "/convenioviewlikes", 'id=' + id + "&user=" + user, this.options);
  }
  getAvatars(genero, rol) {
    return this.http.post(this.url + '/avatars', 'genero=' + genero + "&rol=" + rol, this.options);
  }
  getVigentes(user) {
    return this.http.post(this.url + '/vigentes', "user=" + user, this.options);
  }
  getPreguntas() {
    return this.http.get(this.url + "/preguntas");
  }

  getProgramacion(id, tipo = null) {
    return this.http.post(this.url + '/programacion', 'oper=' + id + "&tipo=" + tipo, this.options);
  }

  getCatalogo(categ) {
    return this.http.post(this.url + "/catalogo", 'categ=' + categ, this.options);
  }

  getCash(id) {
    return this.http.post(this.url + '/cash', 'user=' + id, this.options);
  }

  getCashUser(id) {
    return this.http.post(this.url + '/cashuser', 'user=' + id, this.options);
  }

  getMovimientosOne(id, date) {
    return this.http.post(this.url + '/movimientosone', 'user=' + id + "&fecha=" + date, this.options);
  }

  getMovimientosMes(id) {
    return this.http.post(this.url + '/movimientosmes', 'user=' + id, this.options);
  }

  getMovimientosTmes(id) {
    return this.http.post(this.url + '/movimientostmes', 'user=' + id, this.options);
  }

  getRedimidos(id) {
    return this.http.post(this.url + '/redimidos', 'user=' + id, this.options);
  }

  getRanking(id, rol) {
    return this.http.post(this.url + '/ranking', 'empleado=' + id + "&rol=" + rol, this.options);
  }


  getNotificaciones(user) {
    return this.http.post(this.url + "/notificaciones", 'empleado=' + user, this.options);
  }
  getEncuestas(user) {
    return this.http.post(this.url + "/encuestas", 'empleado=' + user, this.options);
  }
  getNotificacionesnl(user) {
    return this.http.post(this.url + "/notificacionesnl", 'empleado=' + user, this.options);
  }
  getEncuestasnl(user) {
    return this.http.post(this.url + "/encuestasnl", 'empleado=' + user, this.options);
  }
  getNotificacionleida(id, user) {
    return this.http.post(this.url + "/notificacionleida", 'empleado=' + user + "&id=" + id, this.options);
  }
  detailNotificacion(id, user) {
    var rtn = this.http.post(this.url + '/notificaciondata', 'empleado=' + user + "&id=" + id, this.options);
    return rtn;
  }
  sendRespuestas(form: any, id: string, user) {
    var rtn = this.http.post(this.url + '/getpreguntas', 'form=' + JSON.stringify(form) + "&id=" + id + "&user=" + user, this.options);
    return rtn;
  }

  getDates() {
    return this.http.post(this.url + '/dates', null, this.options);
  }

  getTipoCasos() {
    return this.http.post(this.url + '/casos', 'select=true', this.options);
  }

  sendCaso(form, user) {
    return this.http.post(this.url + '/casos', 'form=' + JSON.stringify(form) + "&user=" + user, this.options);
  }
  sendInquietud(form, user) {
    return this.http.post(this.url + '/casos', 'seguimiento=true&forms=' + JSON.stringify(form) + "&user=" + user, this.options);
  }

  getInquietudes(user) {
    return this.http.post(this.url + '/casos', "list=true&user=" + user, this.options);
  }

  getProducto(id: string) {

    var rtn = this.http.post(this.url + '/getproducto', 'id=' + id, this.options);
    return rtn;
  }

  solictarProducto(id: string, user, cantidad) {
    var rtn = this.http.post(this.url + '/redencion', 'id=' + id + "&user=" + user + "&count=" + cantidad, this.options);
    return rtn;
  }

  tipmotprog() {

    return this.http.get(this.url + "/listtipmotprog");
  }

  setdenario(form, id, user) {
    return this.http.post(this.url + '/denario', 'id=' + id + "&user=" + user + "&form=" + JSON.stringify(form), this.options);
  }

  detalconv(id) {
    return this.http.post(this.url + '/detalconv', 'id=' + id, this.options);
  }

  indnovedades(id, user) {
    return this.http.post(this.url + '/indnovedades', 'id=' + id + "&user=" + user, this.options);
  }

  solprog(user, code, form) {
    return this.http.post(this.url + '/solprog', 'form=' + JSON.stringify(form) + "&user=" + user + "&code=" + code, this.options);
  }

  solsprog(user) {
    return this.http.post(this.url + '/getsolicitudes', "user=" + user, this.options);
  }

  getExtras(id, week) {
    return this.http.post(this.url + '/horasextras', 'user=' + id + "&week=" + JSON.stringify(week), this.options);
  }

  calsolprog(id, cal) {
    return this.http.post(this.url + '/setcalificacion', 'id=' + id + "&cal=" + cal, this.options);
  }

  recibir(id, val) {
    return this.http.post(this.url + '/recibirprod', 'id=' + id + "&val=" + val, this.options);
  }

  redsocial() {
    return this.http.get(this.url + "/redes");
  }

  bonolink(id) {
    return this.http.post(this.url + '/bonolink', 'id=' + id, this.options);
  }

  getvacaciones(id) {
    return this.http.post(this.url + "/getvacaciones", "user=" + id, this.options);
  }

  setvacaciones(id, fch_ini, fch_fin, tipo, id_fechas) {
    return this.http.post(this.url + '/solvac', 'form=' + JSON.stringify([fch_ini, fch_fin, tipo]) + "&user=" + id + "&id_fechas=" + id_fechas, this.options);
  }

  getsolvacaciones(id) {
    return this.http.post(this.url + "/getsolvac", "user=" + id, this.options);
  }

  getnovedades(id) {
    return this.http.post(this.url + "/getnovedades", "user=" + id, this.options);
  }

  getdatvac(id) {
    return this.http.post(this.url + "/getdayvac", "user=" + id, this.options);
  }

  getcupvac(id, user) {
    return this.http.post(this.url + "/getcupvac", "user=" + user + "&id=" + id, this.options);
  }
  getpdfprogramacion(id, tipo = null) {
    return this.http.post(this.url + '/programacion', 'oper=' + id + "&tipo=" + tipo + "&pdf=true", this.options);
  }
  deletevac(id, user, motivo) {
    return this.http.post(this.url + '/rechazarvac', 'id=' + id + "&user=" + user + "&motivo=" + motivo, this.options);
  }

  setochosiete(id, tipo) {
    return this.http.post(this.url + '/setochosiete', 'id=' + id + "&tipo=" + tipo, this.options);
  }

  novpendientes(user) {
    return this.http.post(this.url + '/novpendientes', 'user=' + user, this.options);
  }

  datanovedad(user, id) {
    return this.http.post(this.url + '/datanovedad', 'user=' + user + '&id=' + id, this.options);
  }

  respnovedad(user, id, descripcion) {
    return this.http.post(this.url + '/respnovedad', 'user=' + user + '&id=' + id + "&descripcion=" + descripcion, this.options);
  }
  respunovedad(user, id) {
    return this.http.post(this.url + '/getrespuestas', 'user=' + user + '&id=' + id, this.options);
  }

  btns(rol, user) {
    return this.http.post(this.url + '/btns', 'rol=' + rol+ "&user=" + user, this.options);
  }
  getpatios(user) {
    return this.http.post(this.url + '/getpatios', 'user=' + user + "&view=1", this.options);
  }

  cambiopatio(user, form) {
    return this.http.post(this.url + '/cambiopatio', 'form=' + JSON.stringify(form) + "&user=" + user, this.options);

  }

  datacampat(user) {
    return this.http.post(this.url + '/datacampat', 'user=' + user, this.options);
  }

  eventos(user) {
    return this.http.post(this.url + '/eventos', 'user=' + user, this.options);
  }

  evento(user, id) {
    return this.http.post(this.url + '/evento', 'user=' + user + "&id=" + id, this.options);
  }

  ganancias(user) {
    return this.http.post(this.url + '/ganancias', 'user=' + user, this.options);
  }

  cashadd(user) {
    return this.http.post(this.url + '/cashadd', 'user=' + user, this.options);
  }

  getMsns(user) {
    return this.http.post(this.url + '/msncontacto', 'user=' + user, this.options);
  }

  getMsn(user, id) {
    return this.http.post(this.url + '/msnresponder', 'user=' + user + "&id=" + id + "&get=true", this.options);
  }
  getMsnResp(user, id) {
    return this.http.post(this.url + '/msnresponder', 'user=' + user + "&id=" + id, this.options);
  }
  sendMsnRespuestas(respuesta, user, id) {
    return this.http.post(this.url + '/msnresponder', 'respuesta=' + respuesta + "&user=" + user + "&id=" + id + "&post=true", this.options);
  }

getlistdatacat(localidad){
  return this.http.post(this.url + '/dataact', 'lists=true&localidad='+localidad, this.options);
}

getdiscapacidades(){
  return this.http.post(this.url + '/discapacidades', '', this.options);
}

addhijo(addForm, user){
  return  this.http.post(this.url + "/addhijo", "addhijo=" + JSON.stringify(addForm) + '&user=' + user, this.options);
      
}

gethijo(user){
  return  this.http.post(this.url + "/gethijos",'user=' + user, this.options);
}
finactdata(user){
  return  this.http.post(this.url + "/actdata",'user=' + user, this.options);
}

compflex(user, rol, fecha){
  return  this.http.post(this.url + "/compflex",'user=' + user+'&rol=' + rol+'&fecha=' + fecha, this.options);
}

compflexdiaope(user, fecha, rol){
  return  this.http.post(this.url + "/compflexdia",'user=' + user+'&fecha=' + fecha+'&rol=' + rol, this.options);
}

estaodmescomflex(fecha, rol){
  return  this.http.post(this.url + "/compflexestado",'fecha=' + fecha+'&rol=' + rol, this.options);
}

casinosaldo(user){
  return  this.http.post(this.url + "/casino",'tipo=cash&usuario=' + user, this.options);
}

casinoautoriza(user){
  return  this.http.post(this.url + "/casino",'tipo=1&usuario=' + user, this.options);
}

getcortes(user){
  return  this.http.post(this.url + "/casino",'tipo=cortes&usuario=' + user, this.options);
}

getCasinoMov(user, corte){
  return  this.http.post(this.url + "/casino",'tipo=2&usuario=' + user+ "&corte="+corte, this.options);
}

}
