import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {
  @Input() data: any;
  @Input() _vermas: boolean;
  canDismiss = true;

  presentingElement = null;
  constructor( public modalController: ModalController, 
    private router: Router, ) { }
  

  ngOnInit() {
    console.log("data modal")
    console.log(this.data)
    this.presentingElement = document.querySelector('.ion-page');
  }

  onTermsChanged(event: Event) {

  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalController.dismiss({
      'dismissed': true
    });
  }
  vermas(id){
    this.dismiss();
    console.log(id)
    window.open(id, '_system')
  }

}
