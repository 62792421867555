import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'resetpass',
    loadChildren: () => import('./resetpass/resetpass.module').then( m => m.ResetpassPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tyc',
    loadChildren: () => import('./tyc/tyc.module').then( m => m.TycPageModule)
  },
  {
    path: 'popover',
    loadChildren: () => import('./popover/popover.module').then( m => m.PopoverPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notificaciones',
    loadChildren: () => import('./notificaciones/notificaciones.module').then( m => m.NotificacionesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'notificacion/:id',
    loadChildren: () => import('./notificacion/notificacion.module').then( m => m.NotificacionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./init/init.module').then( m => m.InitPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'end',
    loadChildren: () => import('./end/end.module').then( m => m.EndPageModule)
  },
  {
    path: 'preguntas',
    loadChildren: () => import('./preguntas/preguntas.module').then( m => m.PreguntasPageModule)
  },
  {
    path: 'infor/:id',
    loadChildren: () => import('./infor/infor.module').then( m => m.InforPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./videos/videos.module').then( m => m.VideosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'envivos',
    loadChildren: () => import('./envivos/envivos.module').then( m => m.EnvivosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'convenios',
    loadChildren: () => import('./convenios/convenios.module').then( m => m.ConveniosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'convenio/:id',
    loadChildren: () => import('./convenio/convenio.module').then( m => m.ConvenioPageModule),
    canActivate: [AuthGuard]
  },
  
  {
    path: 'avatars',
    loadChildren: () => import('./avatars/avatars.module').then(m => m.AvatarsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'programacion',
    loadChildren: () => import('./programacion/programacion.module').then(m => m.ProgramacionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'redencion',
    loadChildren: () => import('./redencion/redencion.module').then(m => m.RedencionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ranking',
    loadChildren: () => import('./ranking/ranking.module').then(m => m.RankingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ayuda',
    loadChildren: () => import('./ayuda/ayuda.module').then(m => m.AyudaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'inquietudes',
    loadChildren: () => import('./inquietudes/inquietudes.module').then(m => m.InquietudesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'producto/:id',
    loadChildren: () => import('./producto/producto.module').then(m => m.ProductoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'solprog',
    loadChildren: () => import('./solprog/solprog.module').then( m => m.SolprogPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'solsprog',
    loadChildren: () => import('./solsprog/solsprog.module').then( m => m.SolsprogPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'gestion',
    loadChildren: () => import('./gestion/gestion.module').then( m => m.GestionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'denario/:id',
    loadChildren: () => import('./denario/denario.module').then( m => m.DenarioPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'detailconv/:id',
    loadChildren: () => import('./detailconv/detailconv.module').then( m => m.DetailconvPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'indicadores/:id',
    loadChildren: () => import('./indicadores/indicadores.module').then( m => m.IndicadoresPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'extras',
    loadChildren: () => import('./extras/extras.module').then( m => m.ExtrasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'ficha',
    loadChildren: () => import('./ficha/ficha.module').then( m => m.FichaPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vacaciones',
    loadChildren: () => import('./vacaciones/vacaciones.module').then( m => m.VacacionesPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'bono/:id',
    loadChildren: () => import('./bono/bono.module').then( m => m.BonoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'encuestas',
    loadChildren: () => import('./encuestas/encuestas.module').then( m => m.EncuestasPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'novedad/:id',
    loadChildren: () => import('./novedad/novedad.module').then( m => m.NovedadPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modal',
    loadChildren: () => import('./modal/modal.module').then( m => m.ModalPageModule)
  },
  {
    path: 'campat',
    loadChildren: () => import('./campat/campat.module').then( m => m.CampatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'eventos',
    loadChildren: () => import('./eventos/eventos.module').then( m => m.EventosPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'evento/:id',
    loadChildren: () => import('./evento/evento.module').then( m => m.EventoPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'myqr',
    loadChildren: () => import('./myqr/myqr.module').then( m => m.MyqrPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'msnctc',
    loadChildren: () => import('./msnctc/msnctc.module').then( m => m.MsnctcPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'msn/:id',
    loadChildren: () => import('./msn/msn.module').then( m => m.MsnPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'actdat',
    loadChildren: () => import('./actdat/actdat.module').then( m => m.ActdatPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'modaladdress',
    loadChildren: () => import('./modaladdress/modaladdress.module').then( m => m.ModaladdressPageModule)
  },
  {
    path: 'modalchildren',
    loadChildren: () => import('./modalchildren/modalchildren.module').then( m => m.ModalchildrenPageModule)
  },
  {
    path: 'compflex',
    loadChildren: () => import('./compflex/compflex.module').then( m => m.CompflexPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'casino',
    loadChildren: () => import('./casino/casino.module').then( m => m.CasinoPageModule),
    canActivate: [AuthGuard]
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
